<template>
  <div class="pageborder">
    <div class="pageback" v-loading="backupLoading">
      <envir-page-name style="background-color: #ffffff;" :noBack="true" pageName="数据备份" />
      <div class="pageinpadding">
        <div style="margin-left: 16px;">当前备份状态：{{ backupShow ? '备份中' : '已停止备份' }}</div>
        <div style="margin-left: 16px;">备份说明：备份一共保留五份：每5/15/25日、昨日、今日，均为早晨8点备份，新备份会将旧备份覆盖。备份存放位置为代码文件夹上一层的backup文件夹。</div>
        <div style="margin-left: 16px;margin-bottom: 16px;">若需要恢复数据，请立即停止备份并联系管理员。</div>
        <el-button
          size="small"
          :type="backupShow ? 'danger' : 'success'"
          style="margin-left: 16px;margin-bottom: 16px;"
          @click="changeBackup()">{{ backupShow ? '停止备份' : '开始备份' }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';

export default {
  data() {
    return {
      imageUrl: '',
      newLoading: false,
      backupShow: 0,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList(){
      const { result } = await api.post('/searchMatch', {
        searchObj: {
          roomid: '1111111'
        }
      });
      console.log(result);
      this.backupShow = result.rows[0] ? result.rows[0].tableid : 0;
    },
    async changeBackup(){
      try{
        this.newLoading = true;
        const { result } = await api.post('/changebackup', { backup: this.backupShow == 1 ? 0 : 1 });
        console.log(result);
        this.$message.success('新增/修改备份状态成功');
        this.newLoading = false;
        await this.getList();
      }catch(e){
        console.error(e);
        this.newLoading = false;
      }
    }
  }
}
</script>

<style scoped lang="less">
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
